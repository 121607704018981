import styled from 'styled-components';
import { font } from '../../../../styles/atoms/typography';
import { atMinWidth } from '../../../../styles/atoms/breakpoints';
import { colors } from '../../../../styles/atoms/colors';

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  border-top: 1px solid #c0c3d1;
`;

export const BottomContainer = styled.div`
  gap: 16px;
  width: 100%;
  display: grid;
  padding-top: 32px;
  grid-template-columns: repeat(2, 1fr);

  ${atMinWidth.lg`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .info-type {
    ${font('text', 'md', 700)};
    color: ${colors.gray[900]};
  }

  .info-value {
    ${font('text', 'md', 400)};
    color: ${colors.gray[700]};
  }
`;
